import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms ease-in', style({ opacity: 1 })),
  ]),
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* <=> *', [
    group([
      query(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('300ms 500ms ease-out', style({ opacity: 0 })),
        ],
        { optional: true }
      ),

      query(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('300ms 500ms ease-in', style({ opacity: 1 })),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
