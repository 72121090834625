import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Observable, tap, throwError } from 'rxjs';
import { UserAccount } from '../../shared/models/useraccount.model';
import { UserGroup } from '../../shared/models/usergroup.model';
import { UserSession } from '../../shared/models/usersession.model';
import { AuthService } from './data/auth.service';
import { RoleConstantsService } from './roleconstants.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _sessionData: UserSession | null = null;

  get userID(): string | null {
    return this._sessionData ? this._sessionData.userID : null;
  }

  get userName(): string | null {
    return this._sessionData ? this._sessionData.userName : null;
  }

  get userLogin(): string | null {
    return this._sessionData ? this._sessionData.userLogin : null;
  }

  get userAccounts(): UserAccount[] | null {
    return this._sessionData ? this._sessionData.userAccounts : null;
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  get authToken(): string | null {
    return this.authService.getToken();
  }

  constructor(
    private authService: AuthService,
    private roleConstantsService: RoleConstantsService
  ) {
    const authToken = this.authService.getToken()!;

    if (authToken) {
      this.setSessionFromToken(authToken);
    }
  }

  login(userlogin: string, password: string): Observable<any> {
    return this.authService.login(userlogin, password).pipe(
      tap({
        next: (response) => {
          this.setSessionFromToken(response.body);
        },
      })
    );
  }

  logout(withRedirect: boolean = true): void {
    this.authService.logout(withRedirect);
    this.clearSession();
  }

  /**
   * Gets the default user account the user signs into.
   * @returns UserAccount object if a session is active or null if it's not.
   */
  getDefaultAccount(): UserAccount | null {
    return this._sessionData && this._sessionData.userAccounts
      ? this._sessionData.userAccounts.find((account) => account.isDefault) ||
          null
      : null;
  }

  /**
   * Checks with the auth service for token refreshing.
   */
  checkAndRefreshTokenIfNeeded(): Observable<any> {
    return this.authService.checkAndRefreshTokenIfNeeded();
  }

  /**
   * Setting the user session object from the JWT payload.
   * @param token The auth token sent in from the current session. This contains the payload.
   */
  setSessionFromToken(token: string): void {
    try {
      const decodedToken: any = jwtDecode(token);

      let decodedAccounts: UserAccount[] = JSON.parse(
        decodedToken.user_accounts
      ).map((account: any) => this.mapUserAccount(account));

      this._sessionData = {
        userID: decodedToken.user_id,
        userName: decodedToken.user_name,
        userLogin: decodedToken.user_login,
        userAccounts: decodedAccounts,
        userRoles: decodedAccounts
          .flatMap((account) => account.groups)
          .flatMap((group: any) => group.roles as any)
          .map((role) => role.role_name),
        expiration: decodedToken.exp,
      };
    } catch {
      this.logout(false);
    }
  }

  /**
   * Checks if the user has a specific role.
   * @param role - The role to check for.
   * @returns boolean - True if the user has the role, false otherwise.
   */
  hasRole(role: string): boolean {
    const allRoles = this._sessionData?.userRoles!;
    return allRoles.includes(role);
  }

  /**
   * Checks if the user has any role from a list of roles.
   * @param roles - An array of roles to check.
   * @returns boolean - True if the user has at least one of the roles, false otherwise.
   */
  hasAnyRole(roles: string[]): boolean {
    const userRoles = this._sessionData?.userRoles!;

    const requiredRoles = roles.includes(this.roleConstantsService.roles.ADMIN)
      ? roles
      : [this.roleConstantsService.roles.ADMIN, ...roles];

    return requiredRoles.some((role) => userRoles.includes(role));
  }

  /**
   * An object mapper for our user payload.
   * @param userAccount UserAccount JSON object to map.
   * @returns UserAccount front end object.
   */
  private mapUserAccount(userAccount: any): UserAccount {
    return {
      clientID: userAccount.client_id,
      clientName: userAccount.client_name,
      accountID: userAccount.account_id,
      isDefault: userAccount.is_default,
      groups: userAccount.groups.map((group: any) => this.mapUserGroup(group)),
    };
  }

  private mapUserGroup(userGroup: any): UserGroup {
    return {
      groupID: userGroup.group_id,
      groupName: userGroup.group_name,
      roles: userGroup.roles,
    };
  }

  /**
   * Clearing out the session for unauthenticated scenarios.
   */
  private clearSession(): void {
    this._sessionData = null;
  }
}
