import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private minimumTime = 500;
  private loaderTimeout: any;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.loadingSubject.asObservable();

  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    if (this.loaderTimeout) {
      clearTimeout(this.loaderTimeout);
    }
    this.loaderTimeout = setTimeout(() => {
      this.loadingSubject.next(false);
    }, this.minimumTime);
  }
}
