import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { BaseAPIService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseAPIService {
  // Key to store the token in sessionStorage
  private tokenKey = 'auth-token';

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(
    !!this.getToken()
  );

  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(httpClient: HttpClient, private router: Router) {
    super(httpClient);
  }

  /**
   * Perform login by sending credentials to the backend API.
   * @param userlogin The user's user login.
   * @param password The user's password.
   * @returns Observable of the login result.
   */
  login(userlogin: string, password: string): Observable<any> {
    const body = { user_login: userlogin, password: password };

    return this.post<HttpResponse<string>>('app/auth', body).pipe(
      tap({
        next: (response) => {
          if (response && response.ok && response.body) {
            this.storeToken(response.body);
            this.isAuthenticatedSubject.next(true);
          }
        },
        error: (error) => {
          console.error('Login failed:', error);
          this.isAuthenticatedSubject.next(false);
          return of(null);
        },
      })
    );
  }

  /**
   * Check if the user is authenticated by checking the observed property.
   * @returns Boolean indicating if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.getValue();
  }

  /**
   * Log the user out by removing our session key and sending them to the login screen.
   */
  logout(withRedirect: boolean = true): void {
    this.removeToken();
    this.isAuthenticatedSubject.next(false);

    if (withRedirect) {
      this.router.navigate(['/auth/login']);
    }
  }

  /**
   * Retrieve the stored token.
   * @returns The token or null if not present.
   */
  getToken(): string | null {
    return sessionStorage.getItem(this.tokenKey);
  }

  /**
   * Checks if our token exists and is expiring, if so refresh it.
   * @returns Observable response of void or the tokens refresh response.
   */
  checkAndRefreshTokenIfNeeded(): Observable<any> {
    const token = this.getToken();
    if (token && this.isTokenExpiringSoon(token)) {
      return this.refreshToken();
    }
    return of(null);
  }

  /**
   * Removing a stored token for error handling.
   */
  private removeToken(): void {
    sessionStorage.removeItem(this.tokenKey);
  }

  /**
   * Storing the token in session storage to limit exposure.
   * @param token JWT token
   */
  private storeToken(token: string): void {
    sessionStorage.setItem(this.tokenKey, token);
  }

  /**
   * Checks if the token is expiring within the next 2 minutes.
   * @param token The JWT
   * @returns true or false
   */
  private isTokenExpiringSoon(token: string): boolean {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiration = new Date(payload.exp * 1000);
    const now = new Date();
    return expiration.getTime() - now.getTime() < 2 * 60 * 1000;
  }

  /**
   * Refreshes the JWT and logs you out if it fails or has already expired.
   * @returns Observable
   */
  private refreshToken(): Observable<any> {
    const token = this.getToken();

    return this.post<HttpResponse<string>>('app/auth/refresh', `"${token}"`, {
      headers: { 'Content-Type': 'application/json' },
    }).pipe(
      tap((response: HttpResponse<string>) => {
        if (response && response.ok && response.body) {
          this.storeToken(response.body);
        } else {
          this.logout();
        }
      }),
      catchError((error) => {
        this.logout();
        return of(error);
      })
    );
  }
}
