export const Roles = {
    ADMIN: 'ADMIN',
    CAN_VIEW_DASHBOARD: 'CAN_VIEW_DASHBOARD',
    CAN_EDIT_DASHBOARD: 'CAN_EDIT_DASHBOARD',
    CAN_VIEW_DOCUMENTS: 'CAN_VIEW_DOCUMENTS',
    CAN_EDIT_DOCUMENTS: 'CAN_EDIT_DOCUMENTS',
    CAN_VIEW_ACCOUNTS: 'CAN_VIEW_ACCOUNTS',
    CAN_EDIT_ACCOUNTS: 'CAN_EDIT_ACCOUNTS',
    CAN_VIEW_JOBS: 'CAN_VIEW_JOBS',
    CAN_EDIT_JOBS: 'CAN_EDIT_JOBS',
    CAN_VIEW_USERS: 'CAN_VIEW_USERS',
    CAN_EDIT_USERS: 'CAN_EDIT_USERS',
    CAN_VIEW_GROUPS: 'CAN_VIEW_GROUPS',
    CAN_EDIT_GROUPS: 'CAN_EDIT_GROUPS',
    CAN_VIEW_CLIENT_SETUP: 'CAN_VIEW_CLIENT_SETUP',
    CAN_EDIT_CLIENT_SETUP: 'CAN_EDIT_CLIENT_SETUP'
}